.RSangria-contect-wrapper {
  height: 89vh;
  position: relative;
  overflow: hidden;
}
.RSangria-send-wrapper {
  height: auto;
  padding: 3rem 0 8rem;
  position: relative;
  overflow: hidden;
}
.RSangria-contact-container {
  height: 100%;
}

.RSangria-contact-bottomwhite-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to top,
    rgb(255, 255, 255),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0)
  );
  z-index: 1;
}

.RSangria-contact-cloud-overlay .cloudegrpbottom {
  width: 100%;
  height: auto;
  position: absolute;
  bottom: -30%;
  right: 0;
  z-index: 1;
}
.RSangria-contact-cloud-overlay .cloudegrpbottom img {
  width: 100%;
}
.RSangria-contact-cloud-overlay .cloudegrpbottom1 img {
  width: 100%;
}
.RSangria-contact-cloud-overlay .cloudegrpbottom1 {
  width: 100%;
  height: auto;
  position: absolute;
  top: -28%;
  transform: rotate(180deg);
  right: 0;
  z-index: 1;
}
.RSangria-contact-cloud-overlay .solocloud {
  width: 50%;
  position: absolute;
  top: -8%;
  right: -10%;
  height: auto;
  z-index: 2;
}
.RSangria-contact-cloud-overlay .solocloud1 {
  width: 50%;
  position: absolute;
  top: 25%;
  left: -10%;
  height: auto;
  opacity: 0.7;
  transform: rotate(25deg);
  z-index: 2;
}
.RSangria-contact-cloud-overlay .solocloud1 img {
  width: 100%;
}
.RSangria-contact-cloud-overlay .solocloud img {
  width: 100%;
}
.RSangria-contact-topwhite-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgb(255, 255, 255),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0)
  );
  z-index: 1;
}

.RSangria-contact-text .for-horiline {
  display: flex;
  align-items: center;
}
.RSangria-horizontal-line {
  border-top: 5px solid white;
  border-radius: 10px;
  width: 150px;
  margin-left: 10px;
  text-shadow: -1px 1px 2px #000;
}
.RSangria-contact-text-continer {
  position: relative;
  height: 100%;
  margin: 0 50% 0 15%;
  z-index: 5;
  display: flex;
  align-items: center;
}
.RSangria-contact-text {
  color: white;
}
.RSangria-contact-h1text {
  font-size: 4rem;
  margin: 0;
  text-shadow: -1px 1px 2px #000;
  line-height: 1;
  font-weight: 700 !important;
}
.RSangria-contact-text p {
  text-shadow: -1px 1px 2px #000;
}
.RSangria-contact-text a {
  text-shadow: -1px 1px 2px #000;
}

.RSangria-address-detailes {
  font-size: 20px;
  margin: 0%;
  color: white;
  text-decoration: none;
}
a.RSangria-address-detailes:hover {
  color: #4a9f0f;
}
.RSangria-address-detailes i {
  width: 25px;
  text-align: center;
}
.RSangria-contact-text h4 {
  margin-top: 2rem;
  width: auto;
  background-color: rgba(255, 255, 255, 1);
  color: #4a9f0f;
  width: 200px;
  border-radius: 20px;
  text-align: center;
  text-transform: uppercase;
}
.conleafgrp21 {
  top: 0%;
  right: 7%;
  width: 145px;
  transform: rotate(174deg);
  height: auto;
}
.conleafgrp3 {
  top: -10%;
  left: 10%;
  width: 30%;
  height: auto;
}
.conleafblur6 {
  top: 7%;
  left: 6%;
  transform: rotate(167deg);
  width: 70px;
  height: auto;
}
.colleafblur1 {
  bottom: 3%;
  right: 11%;
  transform: rotate(85deg);
  width: 75px;
  height: auto;
}
.colleafblur11 {
  bottom: 8%;
  right: 6%;
  transform: rotate(185deg);
  width: 50px;
  height: auto;
}
.RSangria-sendcontact-box {
  height: 100%;
}
.RSangria-sandcontactgrid-box {
  display: grid;
  grid-template-columns: 3rem 1fr 3rem;
  position: relative;
  height: 100%;
  align-items: center;
}
.RSangria-sandcontactgrid-box .RSangria-side-navigation {
  grid-column: 2/3;
  margin-top: 3vh;
}
.RSangria-sandcontactgrid-box .RSangria-side-navigation p {
  font-weight: bold;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  margin-bottom: 0.6rem;
  color: #576f4f;
}
.RSangria-sandcontactgrid-box .RSangria-varticle-line {
  margin-left: 3px;
  margin-bottom: 0.6rem;
  border-left: 1px solid #4a9f0f;
  height: 60px;
}
.RSangria-main-sendcontact-sec {
  position: relative;
  grid-column: 2/3;
}
.RSangria-main-sendcontact-sec .RSangria-big-hedding {
  position: absolute;
  width: 100%;
}
.RSangria-main-sendcontact-sec .RSangria-big-hedding {
  position: absolute;
  top: -15%;
  right: 0;
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
}
.RSangria-main-sendcontact-sec .RSangria-big-hedding h1 {
  font-size: 15vw;
  color: #a4e373;
  margin-bottom: 5rem;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}
.RSangria-main-sendcontact-heding {
  display: flex;
  justify-content: center;
  margin-top: 4vh;
  margin-bottom: 4vh;
  width: 100%;
}
.RSangria-main-sendcontact-heding h1 {
  font-family: "Times New Roman", Times, serif;
  color: #576f4f;
  position: relative;
  z-index: 2;
}
.RSangria-sendsetailes {
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
  z-index: 2;
  padding: 0 3rem;
}
.RSangria-main-sendcontact-send {
  width: 65%;
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
}
.RSangria-main-sendcontact-detailes {
  width: 35%;
  padding-top: 2rem;
  color: #576f4f;
}
.inputtag-sendcontect {
  padding: 1rem 2rem;
  border: 2px solid #e3b76f;
  margin: 1rem 0;
  color: #576f4f;
  background-color: transparent;
  background-color: rgba(255, 255, 255, 0.1);
}
.inputtag-sendcontect:focus {
  outline: 2px solid rgba(227, 182, 111, 0.4);
  background-color: rgba(255, 255, 255, 0.4);
}
.RSangria-end-inner {
  display: flex;
}
.RSangria-end-inner input {
  margin: 1rem 10px;
}
.RSangria-send-innner-inner {
  width: 50%;
  display: flex;
  flex-direction: column;
  color: #576f4f;
}
.RSangria-send-innner-inner label {
  padding: 0 0 0 10px;
}
.RSangria-sendcontact-btn {
  border: 2px solid #e3b76f;
  background-color: white;
  text-transform: capitalize;
  color: #e3b76f;
  border-radius: 5px;
  margin-left: auto;
  transition: all 0.3s ease;
  padding: 0.5rem 3rem;
  width: 100%;
}
.RSangria-send-innner-inner.textarea textarea {
  margin-left: 10px;
  margin-right: 10px;
}
.RSangria-sendcontact-btn:hover {
  background-color: rgba(227, 182, 111, 0.2);
  color: black;
}
.RSangria-main-sendcontact-detailes p {
  padding-left: 2rem;
  margin-bottom: 2rem;
}
.RSangria-main-sendcontact-detailes h4 {
  background-color: #4a9f0f;
  color: #a4e373;
  width: 220px;
  padding: 1rem 2rem;
  border-radius: 50px;
  font-size: 18px;
}
.aboutuslead img {
  width: 100%;
}
.suceesmsg {
  width: 100%;
  color: #155724;
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
  border-radius: 0.25rem;
  padding: 0.6rem 1.25rem;
  margin: 0%;
  display: none;
}
.unsuccessmsg {
  width: 100%;
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 0.25rem;
  padding: 0.6rem 1.25rem;
  margin: 0%;
  display: none;
}
.sendbutton {
  width: 100%;
}
.loddingofbutton {
  width: 100%;
  display: none;
  animation: mymove 2s infinite;
}
.sendbutton-wrapper {
  margin: 10px auto;
  max-width: 500px;
  padding: 0 10px;
}

@keyframes mymove {
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media only screen and (max-width: 1111px) {
  .RSangria-map-container {
    background-position: 80% 60%;
  }
  .RSangria-sandcontactgrid-box {
    grid-template-columns: 0rem 1fr 0rem;
  }
}
@media only screen and (max-width: 880px) {
  .RSangria-sendsetailes {
    flex-direction: column;
  }
  .RSangria-send-wrapper {
    height: auto;
  }
  .RSangria-main-sendcontact-detailes {
    width: 100%;
  }
  .RSangria-main-sendcontact-send {
    width: 100%;
  }
  .RSangria-main-sendcontact-detailes {
    display: flex;
    justify-content: space-around;
    text-align: center;
  }
  .RSangria-main-sendcontact-detailes p {
    padding: 0%;
  }
}

@media only screen and (max-width: 650px) {
  .RSangria-map-container {
    background-position: 100% 50%;
  }
  .RSangria-contact-text {
    text-align: center;
  }
  .RSangria-contact-text-continer {
    margin: 0 15%;
  }
  .RSangria-contact-text h4 {
    margin-top: 12rem;
    width: 100%;
  }
  .for-horiline {
    justify-content: center;
  }
  .RSangria-address-detailes {
    font-size: 16px;
  }
  .RSangria-main-sendcontact-detailes {
    flex-direction: column;
  }
  .RSangria-main-sendcontact-detailes h4 {
    margin-left: auto;
    margin-right: auto;
  }
  .RSangria-sendsetailes {
    padding: 0%;
  }
  .RSangria-end-inner {
    flex-direction: column;
  }
  .RSangria-send-innner-inner {
    width: 100%;
  }
  .colleafblur1 {
    right: 24%;
  }
  .RSangria-leaf-sand .colsandleafblur11 {
    width: 150px;
  }
  .RSangria-leaf-sand .colsandleafblur3 {
    width: 70px;
  }
  .RSangria-leaf-sand .colsandleafblur4 {
    width: 62px;
  }
}

@media only screen and (max-width: 500px) {
  .RSangria-main-sendcontact-send {
    padding: 0 15px;
  }
  .inputtag-sendcontect {
    margin-left: 0% !important;
    margin-right: 0% !important;
  }
}
