@import url("https://fonts.googleapis.com/css2?family=Alata&family=Cormorant+Infant:wght@300;400;500;600;700&family=Libre+Baskerville:wght@400;700&display=swap");
body {
  margin: 0;
  font-family: "Cormorant Infant", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently*/
  overflow-x: hidden !important;
  width: 100vw !important;
  scroll-behavior: smooth !important;
}

code {
  font-family: "Cormorant Infant", serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Cormorant Infant", serif;
}
p,
a,
span {
  font-family: "Cormorant Infant", serif;
}
.position-absolute {
  position: absolute;
}
.realSangria-container {
  max-width: 1600px;
  margin: auto;
}
@keyframes realSangria-blurred-letters {
  0% {
    -webkit-filter: blur(10px);
    filter: blur(10px);
    opacity: 0;
  }
  20% {
    -webkit-filter: blur(10px);
    filter: blur(10px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@keyframes fadeInbackHeadding {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.4;
  }
}
.vamtam-word {
  position: relative;
  display: inline-flex;
  margin-right: 1rem;
}
.vamtam-word:last-child {
  margin: 0%;
}
.vamtam-word .vamtam-letter {
  opacity: 0;
}
/* .vamtam-word .vamtam-letter.blurred {
  animation-duration: 1.6s !important;
}
.vamtam-word .vamtam-letter:not(.blurred) {
  animation-duration: 1.6s !important;
} */
.vamtam-word .blurred {
  font-family: "Cormorant Infant", serif;
  font-weight: 300;
  /* line-height: 1.1em; */
  letter-spacing: 0;
}
@keyframes vamtam-grow-left {
  0% {
    -webkit-clip-path: inset(0 100% 0 0);
    clip-path: inset(0 100% 0 0);
  }

  100% {
    -webkit-clip-path: inset(0);
    clip-path: inset(0);
  }
}
@keyframes vamtam-grow-right {
  0% {
    -webkit-clip-path: inset(0 0 0 100%);
    clip-path: inset(0 0 0 100%);
  }

  100% {
    -webkit-clip-path: inset(0);
    clip-path: inset(0);
  }
}
@keyframes vamtam-grow-down {
  0% {
    -webkit-clip-path: inset(0 0 100% 0);
    clip-path: inset(0 0 100% 0);
  }

  100% {
    -webkit-clip-path: inset(0);
    clip-path: inset(0);
  }
}
@keyframes sangriabottole-come-down {
  0% {
    opacity: 0;
    transform: translateY(-150px);
  }
  30% {
    opacity: 0;
    transform: translateY(-150px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
.colored {
  color: #e3b76f;
}
.realSangria-container h2 {
  font-size: 40px;
  line-height: 1.1em;
  color: #545d5c;
}
.realSangria-container p {
  color: #545d5c;
  font-size: 22px;
  line-height: 1.4em;
}
