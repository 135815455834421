.PageHeroSection-wrapper {
  height: 70vh;
}
.PageHeroSection-mainimmage-container {
  width: 100%;
  height: 100%;
  position: relative;
}
.PageHeroSection-mainimmage {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.PageHeroSection-mainimmage-container h1 {
  position: absolute;
  top: calc(50% + 2.75rem);
  left: 50%;
  z-index: 2;
  color: white;
  transform: translate(-50%, -50%);
  font-weight: 300 !important;
  font-size: 70px;
  text-align: center;
}
@media only screen and (max-width: 991px) {
  .PageHeroSection-mainimmage-container h1 {
    top: 50%;
  }
}
@media only screen and (max-width: 500px) {
  .PageHeroSection-mainimmage-container h1 {
    font-size: 50px;
  }
}
