.ReactModal__Overlay {
  z-index: 1000;
}
.ReactModal__Content {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  margin: 0 auto;
  outline: none !important;
}

.modal-transition-here {
  margin: 5rem auto;
  transform: translateY(-50px);
  transition: 0.25s ease-out all;
  opacity: 0;
  overflow: hidden;
  padding: 0px;
  z-index: 1000;
}
.imagemodel.modal-transition-here {
  max-width: 700px;
  height: calc(100% - 10rem);
}
.videomodel.modal-transition-here {
  max-width: 75vw;
  height: calc(100% - 10rem);
}
.modal-transition-here.open {
  transform: translateX(0px) translateY(0px) scale(1);
  opacity: 1;
}

.model-wrapper {
  transition: 0.5s ease all;
  padding: 20px;
}
.close-button {
  width: 40px;
  height: 40px;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  border: none;
  background-color: #fff;
  font-weight: 300;
  font-size: 30px;
  cursor: pointer;
}
.model-image-wrapper {
  padding: 0 20px;
  width: 100%;
  height: calc(100% - 80px);
}
.model-image-wrapper img {
  width: 100%;
  height: 90%;
  object-fit: contain;
}
.model-image-wrapper p {
  margin: 0;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: 600;
}
.ReactModal__Overlay {
  transition: opacity 0.3s ease;
  opacity: 0;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
.model-video-wrapper {
  padding: 20px;
  width: 100%;
  height: calc(100% - 80px);
}
.model-video-wrapper iframe {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
