.OurSangria-main-headding-wrapper {
  min-height: 400px;
  height: 400px;
  margin-top: 5vh;
}
.OurSangria-main-headding-wrapper .realSangria-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.OurSangria-detailes-headding {
  position: relative;
  max-width: 760px;
}
.OurSangria-detailes-headding .smallheadding {
  text-align: center;
  color: #e3b76f;
  margin-bottom: 1.5rem;
  font-family: alata, Sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  font-style: normal;
  line-height: 1.3em;
  letter-spacing: 10px;
}
.OurSangria-detailes-headding .animated-headding {
  text-align: center;
  font-size: 60px;
  font-style: normal;
  line-height: 1.1em;
  letter-spacing: 0;
  margin-bottom: 1.5rem;
}
.OurSangria-detailes-headding .background-hedding-image {
  position: absolute;
  top: -20%;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
  opacity: 0.4;
  height: 330px;
}
.OurSangria-main-detiles-wrapper {
  margin: 3rem 0 10rem;
}
.OurSangria-main-detiles-wrapper .realSangria-container {
  max-width: 1600px;
}
.OurSangria-detailes-wrapper {
  display: flex;
  align-items: center;
}

.OurSangria-detailes-image.left.popularDrinks {
  position: relative;
  background-color: #000;
  height: 400px;
  -webkit-clip-path: inset(0 100% 0 0);
  clip-path: inset(0 100% 0 0);
}
.animate-image-left .OurSangria-detailes-image.left.popularDrinks {
  -webkit-animation-name: vamtam-grow-left;
  animation-name: vamtam-grow-left;
  will-change: clip-path;
  animation-duration: 2s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
}
.OurSangria-detailes-image.left.popularDrinks::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../images/real-sangria-can-2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 60%;
  opacity: 0.85;
}
.OurSangria-detailes-wrapper.left .OurSangria-detailes-text {
  width: 55%;
}
.OurSangria-detailes-wrapper.left
  .OurSangria-detailes-text
  .OurSangria-detailes-text-inner {
  padding: 0 15%;
  margin: 0% 7% 0% 0%;
}
.OurSangria-detailes-text .OurSangria-detailes-text-inner h2 {
  margin-bottom: 25px;
}
.OurSangria-detailes-text .OurSangria-detailes-text-inner p {
  margin: 0%;
}
.OurSangria-detailes2-wrapper {
  padding: 0 10% 0;
  margin: 7rem 0;
}
.OurSangria-detailes2-wrapper-inner h2 {
  margin-bottom: 25px;
  text-align: center;
}

.OurSangria-detailes2-wrapper-inner p {
  text-align: center;
}
.OurSangria-detailes2-wrapper-inner p:last-child {
  margin: 0%;
}
.OurSangria-detailes-image-parent {
  width: 45%;
  height: 400px;
}
.OurSangria-detailes-image.right.MakeitReal {
  position: relative;
  background-color: #000;
  height: 100%;
  width: 100%;
}
.OurSangria-detailes-image.right.MakeitReal::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../images/real-sangria-can-1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 20%;
  opacity: 0.85;
}
.OurSangria-detailes-wrapper.right
  .OurSangria-detailes-text
  .OurSangria-detailes-text-inner {
  padding: 0 15%;
  margin: 0% 0% 0% 7%;
}
.OurSangria-detailes-wrapper.right .OurSangria-detailes-text {
  width: 55%;
}
.OurSangria-create-real-moment {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 10px;
  width: 100%;
  padding: 0 10px;
}
.OurSangria-create-real-moment-first,
.OurSangria-create-real-moment-third,
.OurSangria-create-real-moment-forth,
.OurSangria-create-real-moment-fifth {
  height: 300px;
}
.OurSangria-create-real-moment-first,
.OurSangria-create-real-moment-second,
.OurSangria-create-real-moment-third,
.OurSangria-create-real-moment-forth,
.OurSangria-create-real-moment-fifth {
  overflow: hidden;
  cursor: pointer;
}
.OurSangria-create-real-moment-third,
.OurSangria-create-real-moment-fifth {
  -webkit-clip-path: inset(0 0 0 100%);
  clip-path: inset(0 0 0 100%);
}
.OurSangria-create-real-moment-first,
.OurSangria-create-real-moment-forth {
  -webkit-clip-path: inset(0 100% 0 0);
  clip-path: inset(0 100% 0 0);
}
.OurSangria-create-real-moment-first {
  grid-column: 1;
  grid-row: 1;
  width: 100%;
  position: relative;
  background-color: #000;
}
.animate-image-left .OurSangria-create-real-moment-first {
  -webkit-animation-name: vamtam-grow-left;
  animation-name: vamtam-grow-left;
  will-change: clip-path;
  animation-duration: 2s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
}
.OurSangria-create-real-moment-first::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../images/Image-1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  opacity: 0.85;
  transition: 0.5s ease all;
}
.OurSangria-create-real-moment-second-perent {
  grid-row: 1 / 3;
  grid-column: 2;
}
.OurSangria-create-real-moment-second-perent .animated-element-wrapper {
  height: 100%;
  width: 100%;
}
.OurSangria-create-real-moment-second {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #000;
  -webkit-clip-path: inset(0 0 100% 0);
  clip-path: inset(0 0 100% 0);
}
.animate-image-left .OurSangria-create-real-moment-second {
  -webkit-animation-name: vamtam-grow-down;
  animation-name: vamtam-grow-down;
  will-change: clip-path;
  animation-duration: 2s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
}
.OurSangria-create-real-moment-second::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../images/real-sangria-can-2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  opacity: 0.85;
  transition: 0.5s ease all;
}
.OurSangria-create-real-moment-third {
  grid-column: 3;
  grid-row: 1;
  width: 100%;
  position: relative;
  background-color: #000;
}
.animate-image-right .OurSangria-create-real-moment-third {
  -webkit-animation-name: vamtam-grow-right;
  animation-name: vamtam-grow-right;
  will-change: clip-path;
  animation-duration: 2s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
}
.OurSangria-create-real-moment-third::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../images/Image-3.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  opacity: 0.85;
  transition: 0.5s ease all;
}
.OurSangria-create-real-moment-forth {
  grid-column: 1;
  grid-row: 2;

  width: 100%;
  position: relative;
  background-color: #000;
}
.animate-image-left .OurSangria-create-real-moment-forth {
  -webkit-animation-name: vamtam-grow-left;
  animation-name: vamtam-grow-left;
  will-change: clip-path;
  animation-duration: 2s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
}
.OurSangria-create-real-moment-forth::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../images/Image-4.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  opacity: 0.85;
  transition: 0.5s ease all;
}
.OurSangria-create-real-moment-fifth {
  grid-column: 3;
  grid-row: 2;
  width: 100%;
  position: relative;
  background-color: #000;
}
.animate-image-right .OurSangria-create-real-moment-fifth {
  -webkit-animation-name: vamtam-grow-right;
  animation-name: vamtam-grow-right;
  will-change: clip-path;
  animation-duration: 2s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
}
.OurSangria-create-real-moment-fifth::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../images/real-sangria-can-1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 20%;
  opacity: 0.85;
  transition: 0.5s ease all;
}
.OurSangria-detailes2-wrapper.gallary {
  margin-bottom: 4rem;
}
.OurSangria-create-real-moment-fifth:hover::before,
.OurSangria-create-real-moment-forth:hover:before,
.OurSangria-create-real-moment-third:hover:before,
.OurSangria-create-real-moment-second:hover::before,
.OurSangria-create-real-moment-first:hover::before {
  opacity: 1;
  transform: scale(1.1) rotate(1deg);
}
.OurSangria-order-partners {
  width: 100%;
  height: 150px;
  overflow: hidden;
  padding: 0 15px;
  position: relative;
}
.swiper {
  position: unset !important;
}
.swiper-button-next,
.swiper-button-prev {
  bottom: 0%;
  left: 50%;
  border: 2px solid #576f4f;
  padding: 0 20px;
  margin: 0;
  color: #576f4f;
  transition: 0.5s ease;
}
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: 49% !important;
  right: unset !important;
  transform: translateX(-100%);
}
.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: 49% !important;
  left: unset !important;
  transform: translateX(100%);
}
.swiper-button-next:after {
  content: "\2192";
  color: #e3b76f;
  font-size: 16px !important;
}
.swiper-button-prev:after {
  content: "\2190";
  color: #e3b76f;
  font-size: 16px !important;
}
.swiper-button-prev,
.swiper-rtl .swiper-button-next,
.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  border-color: #e3b76f;
  bottom: 0%;
  top: unset !important;
}
.swiper {
  overflow: visible !important;
}
.swiper-slide.slide {
  border: 2px solid #e3b76f;
  transition: 0.5s ease all;
  background-color: white;
  overflow: hidden;
}
.swiper-slide.slide img {
  transition: 0.5s ease all;
}
.swiper-slide.slide .side-link {
  display: flex;
  align-items: center;
  justify-content: center;
}
.swiper-slide.slide:hover {
  background-color: #e3b76f;
}
/* .swiper-slide.slide:hover img {
  opacity: 0.5;
} */

.backgroundtexting-wrapper {
  position: relative;
  overflow: hidden;
  margin-top: 5rem;
}
.RSangria-backtext {
  width: 100%;
  text-align: center;
  font-size: 20vw !important;
  line-height: 0.9 !important;
  color: #e3b76f !important;
  z-index: 0;
  text-transform: uppercase;
  margin: 0%;
  font-weight: 500;
  opacity: 0.1;
  position: relative;
  left: -45%;
  white-space: nowrap;
}
.backtextcontainer {
  position: absolute;
  top: 50%;
  left: 0%;
  z-index: -1;
  transform: translateX(30%) translateY(-50%);
}
.backgroundtexting-wrapper .OurSangria-detailes2-wrapper.gallary:first {
  margin-top: 2rem;
}
.navigationWithinPage {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
}
.navigationWithinPage a {
  width: 30%;
  text-align: center;
  border: 2px solid #e3b76f;
  padding: 10px 0;
  color: #e3b76f;
  margin: 0 10px;
  transition: 0.5s ease all;
  text-decoration: none;
}
.navigationWithinPage a:hover {
  background-color: rgba(227, 182, 111, 0.2);
  color: black;
}
.OurSangria-detailes-headding p {
  opacity: 0;
}
.OurSangria-detailes-headding p {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 1.3s;
  animation-delay: 2s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
}
.OurSangria-detailes-headding .background-hedding-image {
  opacity: 0;
  -webkit-animation-name: fadeInbackHeadding;
  animation-name: fadeInbackHeadding;
  animation-duration: 1.3s;
  animation-delay: 1.8s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
}
.OurSangria-detailes-text-inner h2,
.OurSangria-detailes2-wrapper-inner h2,
.OurSangria-detailes-text-inner p,
.OurSangria-detailes2-wrapper-inner p {
  opacity: 0;
}
.OurSangria-detailes-text-inner .animate-fadein h2,
.OurSangria-detailes2-wrapper-inner .animate-fadein h2 {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 1.5s;
  animation-delay: 0.3s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
}
.OurSangria-detailes-text-inner .animate-fadein p,
.OurSangria-detailes2-wrapper-inner .animate-fadein p {
  animation-delay: 0.3s;
}
.animated-element-wrapper .OurSangria-detailes-wrapper {
  opacity: 0;
}
.animate-fadein .OurSangria-detailes-wrapper {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 1.5s;
  animation-delay: 0.5s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
}
/* .OurSangria-detailes-headding .vamtam-word .blurred {
  animation-delay: 1s !important;
} */
@media screen and (min-width: 900px) and (orientation: portrait) {
  .RSangria-backtext {
    font-size: 38vw !important;
    font-weight: 700;
  }
}
@media only screen and (max-width: 991px) {
  .OurSangria-detailes-wrapper {
    flex-wrap: wrap;
  }
  .OurSangria-detailes-image-parent {
    width: 100%;
  }
  .OurSangria-detailes-wrapper.left .OurSangria-detailes-text,
  .OurSangria-detailes-wrapper.right .OurSangria-detailes-text {
    width: 100%;
  }
  .OurSangria-detailes-wrapper.left
    .OurSangria-detailes-text
    .OurSangria-detailes-text-inner {
    margin-top: 2rem;
    margin-right: 0%;
  }
  .OurSangria-detailes-wrapper.right
    .OurSangria-detailes-text
    .OurSangria-detailes-text-inner {
    margin-bottom: 2rem;
    margin-left: 0%;
  }
}
@media only screen and (max-width: 676px) {
  .OurSangria-main-headding-wrapper {
    height: auto;
    margin: 0%;
    padding: 4rem 15px 2rem;
  }
  .navigationWithinPage {
    flex-direction: column;
  }
  .navigationWithinPage a {
    width: 70%;
    margin: 5px auto;
  }
  .OurSangria-create-real-moment {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 500px) {
  .OurSangria-detailes-wrapper.left
    .OurSangria-detailes-text
    .OurSangria-detailes-text-inner,
  .OurSangria-detailes-wrapper.right
    .OurSangria-detailes-text
    .OurSangria-detailes-text-inner {
    padding: 0 15px;
  }
  .OurSangria-detailes2-wrapper {
    padding: 0 15px;
    margin: 3rem 0 2rem !important;
  }
  .backgroundtexting-wrapper {
    margin: 0%;
  }
  .OurSangria-detailes2-wrapper-inner .animate-fadein p br {
    display: none;
  }
  .OurSangria-detailes2-wrapper-inner h2,
  .OurSangria-detailes2-wrapper-inner p {
    text-align: left;
  }
  .OurSangria-detailes-headding .animated-headding {
    font-size: 45px;
  }
}
