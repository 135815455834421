.ProductPlaveHome-main-wrapper {
  padding: 7rem 0 5rem;
  position: relative;
  overflow: hidden;
}
.animated-element-headding .vamtam-letter.blurred {
  opacity: 0;
}
.realSangria-whoWeAre-container {
  display: flex;
  flex-wrap: wrap;
}
.realSangria-whoWeAre-container:first-child {
  margin-bottom: 10rem;
  height: auto;
}
.realSangria-whoWeAre-container:last-child {
  height: 600px;
}
.realSangria-whoWeAre-detailes {
  width: 50%;
  display: flex;
  align-items: center;
}
.realSangria-whoWeAre-detailes.storybehnd {
  width: 55%;
}
.realSangria-whoWeAre-detailes-inner {
  padding: 0 12%;
}
.realSangria-whoWeAre-detailes h2 {
  text-align: left;
  font-size: 50px;
  font-style: normal;
  line-height: 1.1em;
  letter-spacing: 0;
}
.realSangria-whoWeAre-detailes-headding {
  position: relative;
  margin-bottom: 1rem;
}
.background-hedding-line-image {
  position: absolute;
  top: -25%;
  left: -5%;
  z-index: -1;
  height: 220px;
  width: auto;
  opacity: 0.35;
  mix-blend-mode: multiply;
}
.realSangria-whoWeAre-detailes-headding p {
  color: #e3b76f;
  margin: 0;
  font-family: alata, Sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  font-style: normal;
  line-height: 1.3em;
  letter-spacing: 10px;
}
.animate-headding .vamtam-letter.blurred {
  animation-name: realSangria-blurred-letters;
  animation-duration: 1.3s;
  animation-timing-function: ease-in;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
}
.realSangria-whoWeAre-detailes h2 .vamtam-word {
  position: relative;
  display: inline-flex;
  margin-right: 1rem;
}
.realSangria-whoWeAre-detailes h2 .vamtam-word:last-child {
  margin: 0%;
}
/* .realSangria-whoWeAre-detailes h2 .vamtam-word .vamtam-letter.blurred {
  animation-duration: 1.6s !important;
}
.realSangria-whoWeAre-detailes h2 .vamtam-word .vamtam-letter:not(.blurred) {
  animation-duration: 1.6s !important;
} */
.realSangria-whoWeAre-detailes h2 .vamtam-word .blurred {
  font-family: "Cormorant Infant", serif;
  font-weight: 300;
  line-height: 1.1em;
  letter-spacing: 0;
}
.realSangria-whoWeAre-images {
  width: 50%;
  height: 100%;
  position: relative;
}
.realSangria-whoWeAre-images-container {
  position: relative;
  width: 100%;
  height: 100%;
  /* background-color: antiquewhite; */
}
.realSangria-whoWeAre-images-container .winebottome1-prent {
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  height: 100%;
  z-index: 1;
}
.realSangria-whoWeAre-images-container
  .winebottome1-prent
  .winebottome1-prent-inner {
  background-image: url("../images/storybehindFruites.png");
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;
}
.realSangria-whoWeAre-images-container .winebottome1-prent .winebottome1 {
  height: 100%;
}
.winebottome1 img {
  height: 100%;
}
.realSangria-whoWeAre-images-container .winebottome2-prent {
  height: 65%;
}
.realSangria-whoWeAre-images-container .winebottome2 {
  margin: 0 8%;
  overflow: hidden;
}
.winebottome2 img {
  width: 120%;
  height: 120%;
  object-fit: cover;
  object-position: center center;
}
.infop {
}
.realSangria-whoWeAre-images.storybehnd {
  display: flex;
  align-items: center;
  width: 45%;
  height: 400px;
}
.realSangria-whoWeAre-images.storybehnd
  .OurSangria-detailes-image.right.MakeitReal {
  width: 100%;
}
.realSangria-whoWeAre-images.storybehnd
  .OurSangria-detailes-image.right.MakeitReal::before {
  background-image: url("../images/ourstorey.webp");
  background-position: center center;
}
.realSangria-whoWeAre-detailes-inner p {
  margin: 0%;
}
.animate-image-right {
  width: 100%;
  height: 100%;
}
.animated-element-wrapper .OurSangria-detailes-image.right.MakeitReal {
  -webkit-clip-path: inset(0 0 0 100%);
  clip-path: inset(0 0 0 100%);
}
.animate-image-right .OurSangria-detailes-image.right.MakeitReal {
  -webkit-animation-name: vamtam-grow-right;
  animation-name: vamtam-grow-right;
  will-change: clip-path;
  animation-duration: 2s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
}
.animated-element-wrapper .winebottome2-prent {
  -webkit-clip-path: inset(0 0 100% 0);
  clip-path: inset(0 0 100% 0);
}
.animate-image-right .winebottome2-prent {
  -webkit-animation-name: vamtam-grow-down;
  animation-name: vamtam-grow-down;
  will-change: clip-path;
  animation-duration: 2s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
}
.realSangria-whoWeAre-detailes-inner p {
  opacity: 0;
}
.RealSangria-deatiles-inner p {
  opacity: 0;
}
.animate-fadein p {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 1.3s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
}
.animate-fadein .navigationWithinPage {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 1.3s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
}
.animated-element-wrapper .winebottome1-prent-inner {
  opacity: 0;
}
.animate-winebottole .winebottome1-prent-inner {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 1.3s;
  animation-delay: 0.5s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
}
.animate-winebottole {
  height: 100%;
}
.animated-element-wrapper .winebottome1 {
  opacity: 0;
  transform: translateY(-150px);
}
.animate-winebottole .winebottome1 {
  -webkit-animation-name: sangriabottole-come-down;
  animation-name: sangriabottole-come-down;
  animation-duration: 1.3s;
  animation-delay: 1s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
}
.backgroundFruites {
  position: absolute;
  z-index: -1;
  opacity: 0.1;
  transition: all ease;
}
.backgroundFruites img {
  width: 100%;
  height: 100%;
}
.backgroundFruites.first1 {
  top: -10%;
  left: 40%;
  width: 100px;
  height: 100px;
}
.backgroundFruites.first2 {
  top: -20%;
  left: 10%;
  width: 150px;
  height: 150px;
}
.backgroundFruites.first3 {
  top: -20%;
  left: 27%;
  width: 100px;
  height: 100px;
}
.backgroundFruites.first4 {
  top: 0%;
  left: 92%;
  width: 100px;
  height: 100px;
}
.backgroundFruites.first5 {
  top: 10%;
  left: 84%;
  width: 120px;
  height: 120px;
}
.backgroundFruites.first6 {
  top: 15%;
  left: 55%;
  width: 120px;
  height: 120px;
}
.backgroundFruites.first7 {
  top: -10%;
  left: 68%;
  width: 120px;
  height: 120px;
}
.backgroundFruites.first8 {
  top: 50%;
  left: 43%;
  width: 80px;
  height: 80px;
}
.backgroundFruites.first9 {
  top: 100%;
  left: 75%;
  width: 80px;
  height: 80px;
}
.backgroundFruites.first10 {
  top: 100%;
  left: 52%;
  width: 150px;
  height: 150px;
}
.backgroundFruites.first11 {
  top: 40%;
  left: 63%;
  width: 110px;
  height: 110px;
}
.backgroundFruites.first12 {
  top: 135%;
  left: 12%;
  width: 75px;
  height: 75px;
}
.backgroundFruites.first13 {
  top: 94%;
  left: 45%;
  width: 90px;
  height: 90px;
}
.backgroundFruites.first14 {
  top: 50%;
  left: -2%;
  width: 160px;
  height: 160px;
}
.backgroundFruites.first15 {
  top: 100%;
  left: 10%;
  width: 75px;
  height: 75px;
}
.backgroundFruites.first16 {
  top: 90%;
  left: 30%;
  width: 128px;
  height: 128px;
}
.backgroundFruites.first17 {
  top: 115%;
  left: 75%;
  width: 135px;
  height: 135px;
}
.backgroundFruites.first18 {
  top: 80%;
  left: 93%;
  width: 180px;
  height: 180px;
}
@media screen and (min-width: 900px) and (orientation: portrait) {
  .realSangria-whoWeAre-images-container .winebottome2 {
    height: 100%;
  }
}
@media only screen and (max-width: 991px) {
  .realSangria-whoWeAre-detailes.storybehnd {
  }
  .realSangria-whoWeAre-detailes {
    margin-bottom: 2rem;
  }
  .realSangria-whoWeAre-images.storybehnd {
    padding: 15px;
  }
  .realSangria-whoWeAre-detailes.storybehnd,
  .realSangria-whoWeAre-images.storybehnd,
  .realSangria-whoWeAre-images,
  .realSangria-whoWeAre-detailes {
    width: 100%;
  }
  .realSangria-whoWeAre-container:last-child {
    height: auto;
  }
  .realSangria-whoWeAre-images-container .winebottome2 {
    margin: 0 15px;
  }
  .realSangria-whoWeAre-container:first-child {
    margin-bottom: 5rem;
  }
  .realSangria-whoWeAre-images-container {
    height: 600px;
  }
  .realSangria-whoWeAre-images-container .winebottome2-prent {
    height: 400px;
  }
  .realSangria-whoWeAre-images-container .winebottome2-prent .winebottome2 {
    height: 100%;
  }
  .realSangria-whoWeAre-images-container .winebottome2-prent .winebottome2 img {
    height: 100%;
    width: 100%;
  }
  .winebottome1-prent .animate-winebottole {
    display: flex;
    align-items: flex-end;
  }
  .realSangria-whoWeAre-images-container
    .winebottome1-prent
    .winebottome1-prent-inner {
    height: 80%;
  }
  .realSangria-whoWeAre-container:nth-child(2) .realSangria-whoWeAre-images {
    order: 2;
  }
  .realSangria-whoWeAre-container:nth-child(2) .realSangria-whoWeAre-detailes {
    order: 1;
  }
}
@media only screen and (max-width: 676px) {
}
@media only screen and (max-width: 500px) {
  .realSangria-whoWeAre-detailes-inner {
    padding: 0 15px;
  }
}
