.realSangria-footer-wrapper {
  background-color: #faf8f5;
  position: relative;
}
.realSangria-footer-image {
  width: 100%;
  height: 100%;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(227, 182, 111, 0.2);
  padding: 3rem 0 3.2rem;
}
.realSangria-footer-detailes {
  position: relative;
  z-index: 10;
  color: blavk;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.realSangria-footer-detailes-end-copyright p {
  font-family: alata, Sans-serif;
  font-size: 11px;
  font-weight: 400;
  text-transform: uppercase;
  margin: 0%;
  letter-spacing: 2px;
  color: black;
  text-align: center;
}
.realSangria-footer-detailes-end-copyright h4 {
  text-align: center;
  margin-bottom: 0.7rem;
}
.realSangria-footer-detailes-end-copyright p:nth-child(2) {
  margin-bottom: 1.5rem;
  text-transform: lowercase;
}
.realSangria-footer-detailes-end-copyright p a {
  color: #e3b76f !important;
  transition: 0.5s ease all;
}
.realSangria-footer-detailes-end-copyright p a:hover {
  color: black !important;
}
.realSangria-footer-detailes-social {
  --icon-padding: 0.6em;
  --icon-size: 30px;
  display: flex;
  margin-bottom: 1rem;
}
.realSangria-footer-detailes-social a {
  line-height: var(--icon-size, 25px);
  width: calc(var(--icon-size, 25px) + 2 * var(--icon-padding, 0.5em));
  height: calc(var(--icon-size, 25px) + 2 * var(--icon-padding, 0.5em));
  transition: all 0.5s ease;
  border-radius: 50rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.realSangria-footer-detailes-social a i {
  display: block;
  font-size: var(--icon-size, 25px);
  color: #e3b76f;
  transition: all 0.5s ease;
}
.realSangria-footer-detailes-social a:hover {
  background-color: #e3b76f;
  transform: scale(0.8);
}
.realSangria-footer-detailes-social a:hover i {
  color: black;
}
@media only screen and (max-width: 769px) {
  .realSangria-footer-wrapper {
    height: 43vh;
  }
}
