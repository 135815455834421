.RealSangria-main-wrapper {
  height: 800px;
  position: relative;
  overflow: hidden;
}
.RealSangria-image {
  width: 100%;
  height: 100%;
  background-image: url("../images/whilespace.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.RealSangria-image-inner {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url("../images/whilespace.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
}
.RealSangria-backgroundnameline {
  position: absolute;
  top: 5%;
  left: 0%;
  z-index: 0;
}
.RealSangria-backgroundnameline h2 {
  color: #e9b0a8;
  text-align: center;
  font-size: 15rem;
  z-index: 0;
  text-transform: uppercase;
  font-weight: 600 !important;
  white-space: nowrap;
  line-height: 1;
  opacity: 0.35;
}
.RealSangria-image-inner .realSangria-container {
  position: relative;
}
.RealSangria-deatiles {
  width: 45%;
  text-align: right;
  position: absolute;
  right: 0%;
  bottom: 25%;
  transform: translateY(25%);
}
.RealSangria-deatiles-inner {
  padding: 0 12% 0 5%;
}
.miniheadding {
  color: #e3b76f !important;
  margin: 0;
  font-family: alata, Sans-serif;
  font-size: 14px !important;
  text-transform: uppercase;
  font-style: normal;
  line-height: 1.3em;
  letter-spacing: 10px;
}
.RealSangria-deatiles h2 {
  font-size: 62px;
  font-style: normal;
  line-height: 1.1em;
  letter-spacing: 0;
  margin-bottom: 2rem;
}
.realsangria-productspage-backimage {
  height: 500px;
  width: 100%;
  background-image: url("../images/back-32.jpg");
  background-position: center 100%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.realsangria-productspage-backimage h4 {
  color: white;
  font-size: 45px;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  font-weight: 300;
}
@media only screen and (max-width: 991px) {
  .RealSangria-main-wrapper {
    height: 550px;
  }
  .RealSangria-deatiles {
    width: 100%;
  }
  .realsangria-productspage-backimage h4 {
    font-size: 35px;
    padding: 0 15px;
  }
}
@media only screen and (max-width: 676px) {
  .RealSangria-deatiles-inner {
    padding: 0 15px;
  }
  .RealSangria-image,
  .RealSangria-image-inner {
    background-position: 65% center;
  }
}
