.RSangria-product-wrapper {
  height: 100vh;
  overflow: hidden;
  padding: 3rem 0;
  position: relative;
}
.productteawrapper {
  height: 720px;
}
.productlistofsmallbox .RSangria-product-wrapper {
  height: 80vh;
}
.RSangria-product-box {
  height: 100%;
}
.RSangria-productgrid-box {
  display: grid;
  grid-template-columns: 3rem 1fr 10fr 1fr 3rem;
  position: relative;
  height: 100%;
}
.RSangria-main-product-sec {
  position: relative;
}
.RSangria-main-product_tea {
  grid-column: 2/5;
}
.productteawrapper .RSangria-main-product_tea {
  height: 600px;
}
.RSangria-productgrid-box .RSangria-side-navigation {
  grid-column: 2/3;
  margin-top: 3vh;
  z-index: 3;
  transition: all 0.5s ease;
}
.RSangria-productgrid-box .RSangria-side-navigation p {
  font-weight: bold;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  margin-bottom: 0.6rem;
  color: #576f4f;
}
.side-link {
  text-decoration: none;
}
.RSangria-productgrid-box .RSangria-side-navigation .side-link {
  font-weight: bold;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  margin-bottom: 0.6rem;
}

.RSangria-productgrid-box .RSangria-varticle-line {
  margin-left: 3px;
  margin-bottom: 0.6rem;
  border-left: 1px solid #4a9f0f;
  height: 60px;
}
.RSangria-main-product-heding {
  display: flex;
  justify-content: center;
  margin-top: 8vh;
  position: relative;
}
.RSangria-main-product-heding h1 {
  font-family: "Times New Roman", Times, serif;
  color: #576f4f;
  position: absolute;
  margin-top: 0vh;
  transition: all 0.5s ease;
}
.productteawrapper .RSangria-main-product-heding h1 {
  margin-top: 0vh;
}
.productlistofsmallbox .productteawrapper .RSangria-main-product-heding h1 {
  margin-top: 20vh;
}
/* .RSangria-main-product-slider {
  width: 76vw;
  margin: 0 auto;
} */

.productlistofsmallbox .RSangria-main-product_tea {
  height: 75vh;
}

.RSangria-carosel-imageboc img {
  width: 100%;
}
.RSangria-carosel-imageboc {
  height: 55vh;
  display: flex;
  align-items: flex-end;
}
.productpagelist .RSangria-carosel-imageboc {
  height: 25rem;
}

.RSangria-carosel-detailesbox {
  text-align: center;
  padding: 0 2.5rem;
}
.RSangria-carosel-detailesbox h4 {
  height: 5vh;
  margin-bottom: 2rem;
  font-weight: bolder;
  font-size: 18px;
  color: #576f4f;
}
.RSangria-carosel-detailesbox p {
  height: 3vh;
  font-style: italic;
  color: #576f4f;
}
.RSangria-main-product-sec .RSangria-big-hedding {
  position: absolute;
  top: 0;
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.RSangria-main-product-sec .RSangria-big-hedding h1 {
  height: 250px;
  font-size: 15vw;
  color: #a4e373;
  margin-bottom: 55vh;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}
.RSangria-protealeaf-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 0;
}
.RSangria-proleaf {
  height: 100%;
  position: relative;
}
.RSangria-proleaf .proleafblur6 {
  top: 5%;
  right: -5%;
  transform: rotate(10deg);
  width: 13%;
}
.RSangria-proleaf .proleafblur1 {
  top: 32%;
  right: 5%;
  transform: rotate(-125deg);
  width: 3%;
}
.RSangria-proleaf .proleafgrp1 {
  top: 0%;
  right: 5%;
  width: 5%;
}
.RSangria-proleaf .proleafgrp4 {
  top: 8%;
  right: 25%;
  width: 6%;
}
.RSangria-proleaf .proleafgrp2 {
  top: 15%;
  right: 10%;
  transform: rotate(100deg);
  width: 6%;
}
.RSangria-proleaf .proleafgrp11 {
  top: 15%;
  right: 38%;
  transform: rotate(100deg);
  width: 5%;
}
.RSangria-proleaf .proleafblur3 {
  top: 0%;
  right: 20%;
  transform: rotate(100deg);
  width: 4%;
}
.RSangria-proleaf .proleafgrp31 {
  top: -5%;
  right: 11%;
  transform: rotate(80deg);
  width: 20%;
}
.RSangria-proleaf .proleafgrp32 {
  top: 7%;
  right: 13%;
  transform: rotate(0deg);
  width: 5%;
}
.RSangria-proleaf .proleafblur61 {
  top: 4%;
  right: 12%;
  transform: rotate(234deg);
  width: 4%;
}
.RSangria-proleaf .proleafgrp41 {
  bottom: 4%;
  left: 12%;
  transform: rotate(234deg);
  width: 4%;
}
.RSangria-proleaf .proleafblur5 {
  bottom: -3%;
  left: -1%;
  transform: rotate(189deg);
  width: 8%;
}
.RSangria-proleaf .proleafgrp12 {
  bottom: 2%;
  left: 9%;
  transform: rotate(189deg);
  width: 3%;
}
/* .slide {
  margin-top: 30vh;
} */
.productpagelist {
  transition: 0.5s ease all;
  height: 100%;
}
/* .productpagelist .slide {
  margin-top: -1rem;
}
.productlistofsmallbox .productpagelist .slide {
  margin-top: 35vh;
} */

.backgroundeimage-figure {
  display: flex;
  position: fixed;
  top: 10vh;
  right: 0;
  width: 50%;
  height: 50vh;
  background-size: cover;
  transition: all 2s ease;
  z-index: 0;
  will-change: clip-path;
}
.bottole-figure {
  display: flex;
  align-items: end;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 50%;
  height: 80%;
  background-size: contain;
  background-position: calc(50% - 80px) 100%;
  background-repeat: no-repeat;
  transition: all 0.5s ease;
  z-index: 5;
}
.bottole-750-figure {
  display: flex;
  align-items: end;
  position: fixed;
  bottom: -15px;
  right: 0;
  width: 50%;
  height: 75%;
  background-size: contain;
  background-position: calc(50% + 20px) 100%;
  background-repeat: no-repeat;
  transition: all 0.5s ease;
  z-index: 6;
}
.bottole-tin-figure {
  display: flex;
  align-items: end;
  position: fixed;
  bottom: 9px;
  right: 0;
  width: 50%;
  height: 45%;
  background-size: contain;
  background-position: calc(50% - 185px) 100%;
  background-repeat: no-repeat;
  transition: all 0.5s ease;
  z-index: 6;
}
.bottole-box-figure {
  display: flex;
  align-items: end;
  position: fixed;
  bottom: 10px;
  right: 0;
  width: 50%;
  height: 55%;
  background-size: contain;
  background-position: calc(50% + 110px) 100%;
  background-repeat: no-repeat;
  transition: all 0.5s ease;
  z-index: 4;
}
.hero {
  position: relative;
  width: 100%;
  height: 100vh;
}
.hero:nth-child(1) .backgroundeimage-figure {
  background-image: url("../images/purpleSangria.jpg");
  background-position: center 75%;
}
.hero:nth-child(1) .bottole-figure {
  background-image: url("../images/purple-sangria-1-5.png");
}
.hero:nth-child(1) .bottole-750-figure {
  background-image: url("../images/purple-sangria-750.png");
}
.hero:nth-child(1) .bottole-tin-figure {
  background-image: url("../images/purple-sangria-250.png");
}
.hero:nth-child(1) .bottole-box-figure {
  background-image: url("../images/purple-sangria-5L.png");
}
.hero:nth-child(2) .backgroundeimage-figure {
  background-image: url("../images/whiteSangria.webp");
  background-position: center 75%;
}
.hero:nth-child(2) .bottole-figure {
  background-image: url("../images/white-sangria-1-5.png");
}
.hero:nth-child(2) .bottole-750-figure {
  background-image: url("../images/white-sangria-750.png");
}
.hero:nth-child(2) .bottole-tin-figure {
  background-image: url("../images/white-sangria-250.png");
}
.hero:nth-child(2) .bottole-box-figure {
  background-image: url("../images/white-sangria-5L.png");
}
.hero:nth-child(3) .backgroundeimage-figure {
  background-image: url("../images/redSangria.jpg");
}
.hero:nth-child(3) .bottole-figure {
  background-image: url("../images/red-sangria-1-5.png");
}
.hero:nth-child(3) .bottole-750-figure {
  background-image: url("../images/red-sangria-750.png");
}
.hero:nth-child(3) .bottole-tin-figure {
  background-image: url("../images/red-sangria-250.png");
}
.hero:nth-child(3) .bottole-box-figure {
  background-image: url("../images/red-sangria-5L.png");
}
.hero-inner {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  clip: rect(0, auto, auto, 0);
}
.product-wrapres {
  position: relative;
  overflow: hidden;
}
.ordernow-productspage {
  /* padding: 5rem 0; */
}
.ordernow-productspage-backimage {
  height: 500px;
  width: 100%;
  background-image: url("../images/Group-571.webp");
  background-position: center 35%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.ordernow-productspage-backimage .ordernow-productspage-backimage-overlay {
  position: absolute;
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 0;
  background-color: rgba(0, 0, 0, 0.3);
}
.ordernow-productspage-backimage h4 {
  color: white;
  font-size: 45px;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  font-weight: 300;
  z-index: 1;
}
.ordernowbutton {
  background-color: rgba(227, 182, 111, 0.2);
  color: #e3b76f;
  border: 2px solid #e3b76f;
  padding: 10px 0;
  width: 20%;
  text-decoration: none;
  text-align: center;
  margin: 1rem auto 0;
  transition: 0.5s ease all;
  z-index: 1;
}
.ordernowbutton:hover {
  background-color: #e3b76f;
  color: black;
}
@supports (-webkit-overflow-scrolling: touch) {
  .hero-inner {
    clip: unset;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }
}
.hero__title {
  position: fixed;
  top: 10vh;
  left: 0%;
  padding: 0 2rem 0 7.5vw;
  width: 50%;
  min-height: 50%;
  font-size: 8vw;
  letter-spacing: -0.125rem;
  transition: 0.5s ease all;
  display: flex;
  align-items: center;
  z-index: 1;
}
.backgroundnamescroll {
  position: fixed;
  bottom: 5%;
  width: 100%;
  left: -20%;
}
.backgroundnamescroll h2 {
  color: #f6cefc;
  text-align: center;
  font-size: 15vw;
  z-index: 0;
  text-transform: uppercase;
  font-weight: 600 !important;
  white-space: nowrap;
  line-height: 1;
  opacity: 0.35;
}
.backgroundnamescroll.white h2 {
  color: #e8dfbf;
}
.backgroundnamescroll.red h2 {
  color: #f1b09a;
}
.hero__title h2 {
  text-align: left;
  font-size: 60px;
  font-style: normal;
  line-height: 1.1em;
  letter-spacing: 0;
  margin-bottom: 1.5rem;
  font-weight: 300;
  transition: 1s ease all;
}
.hero__title .realSangria-whoWeAre--headding {
  position: relative;
  padding: 0 2rem;
}
.hero__title p {
  color: #545d5c;
  font-size: 22px;
  line-height: 1.4em;
  letter-spacing: normal;
  transition: 1s ease all;
}
.hero__title p b {
  text-align: center;
  color: #e3b76f;
  margin-bottom: 1.5rem;
  font-family: alata, Sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  font-style: normal;
  line-height: 1.3em;
  letter-spacing: 10px;
  font-weight: 300 !important;
}
.product-wrapres .realSangria-container {
  max-width: 1600px;
}
.hero__title .realSangria-whoWeAre-detailes-headding {
  width: 100%;
}
#section-0 {
  background-color: rgba(246, 206, 252, 0.25);
}
#section-1 {
  background-color: rgba(232, 223, 191, 0.25);
}
#section-2 {
  background-color: rgba(241, 176, 154, 0.25);
}
.realSangria-whoWeAre--headding ul {
  font-size: 20px;
  font-weight: 300 !important;
  letter-spacing: normal;
  font-family: alata, Sans-serif;
  display: flex;
  flex-wrap: wrap;
  padding: 0%;
  transition: 1s ease all;
}
.realSangria-whoWeAre--headding ul li {
  margin-left: 2.2rem;
}
.realSangria-whoWeAre--headding ul li::marker {
  color: #e3b76f;
}
@media only screen and (min-width: 991px) and (max-width: 1400px) and (orientation: landscape) {
  .hero__title {
    padding: 0 2rem 0 3.5vw;
    width: 40%;
  }
  .backgroundeimage-figure,
  .bottole-box-figure,
  .bottole-tin-figure,
  .bottole-750-figure,
  .bottole-figure {
    width: 60%;
  }
  .bottole-tin-figure {
    bottom: 9px;
  }
  .bottole-750-figure {
    bottom: -15px;
    background-position: calc(50% + 35px) 100%;
  }
}
@media only screen and (max-width: 991px) {
  .hero {
    height: auto;
  }
  .hero-inner {
    position: relative;
  }
  .backgroundeimage-figure {
    width: 100%;
    position: relative;
    height: 250px;
  }
  .hero__title {
    position: relative;
    width: 100%;
  }
  .hero-inner div {
    display: flex;
    flex-direction: column;
  }
  .hero-inner div .hero__title {
    order: 1;
    margin-bottom: 2rem;
    padding: 0;
  }
  .hero-inner div .backgroundeimage-figure {
    order: 2;
    display: block;
  }
  .bottlegroup {
    position: relative;
    height: 300px;
    order: 3;
    width: 100%;
  }
  .bottole-figure {
    position: absolute;
    width: 100%;
    height: 120%;
    background-position: calc(50% - 45px) 100%;
  }
  .bottole-750-figure {
    position: absolute;
    width: 100%;
    height: 120%;
    background-position: calc(50% + 15px) 100%;
    bottom: -10px;
  }
  .bottole-tin-figure {
    position: absolute;
    width: 100%;
    height: 65%;
    bottom: 2px;
    background-position: calc(50% - 100px) 100%;
  }
  .bottole-box-figure {
    position: absolute;
    width: 100%;
    height: 80%;
    background-position: calc(50% + 60px) 100%;
  }
  .backgroundnamescroll {
    position: absolute;
    left: -100%;
  }
}
@media only screen and (max-width: 769px) {
  .RSangria-product-wrapper {
    height: 90vh;
  }

  .RSangria-carosel-detailesbox {
    padding: 0 1rem;
  }
  .RSangria-carosel-detailesbox h4 {
    margin-bottom: 1rem;
  }
  .RSangria-carosel-imageboc {
    height: 35vh;
  }
  .RSangria-proleaf .proleafblur5 {
    bottom: -2%;
    left: -3%;
    width: 15%;
  }
  .RSangria-proleaf .proleafgrp41 {
    width: 10%;
  }
  .RSangria-proleaf .proleafgrp12 {
    width: 6%;
  }
  .ordernow-productspage-backimage h4 {
    padding: 0 15px;
    font-size: 35px;
  }
  .ordernowbutton {
    width: 50%;
  }
}
@media only screen and (max-width: 550px) {
  .RSangria-product-wrapper {
    height: 90vh;
  }
  .RSangria-productgrid-box {
    grid-template-columns: 2rem 1fr 10fr 1fr 2rem;
  }

  .RSangria-productgrid-box .RSangria-side-navigation {
    display: none;
  }
  .RSangria-main-product-sec {
    grid-column: 2/5;
  }
  .RSangria-carosel-detailesbox h4 {
    height: 7vh;
    margin-bottom: 1rem;
    font-size: 23px;
  }
  .RSangria-carosel-imageboc {
    height: 50vh;
  }
  .RSangria-carosel-forproductpage {
    height: 42vh;
  }
  /* .RSangria-main-product-slider {
    width: auto;
  } */
  .RSangria-main-product-heding {
    margin: 0%;
    padding: 1rem 0 0 0;
  }
  .RSangria-main-product-heding h1 {
    font-size: 36px;
  }
  .RSangria-big-hedding h1 {
    visibility: hidden;
    position: relative;
  }
  .RSangria-main-product-sec .RSangria-big-hedding h1 {
    width: 100%;
    text-align: center;
  }
  .RSangria-big-hedding h1:after {
    visibility: visible;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 55vw;
    /* text-align: center; */
    width: 100%;
    content: "tea";
  }
  .RSangria-proleaf .proleafblur6 {
    right: -13%;
    width: 25%;
  }
  .RSangria-proleaf .proleafgrp4 {
    top: 24%;
    right: 11%;
    width: 12%;
  }
  .RSangria-proleaf .proleafgrp1 {
    top: 2%;
    right: 28%;
    width: 13%;
  }
  .RSangria-proleaf .proleafgrp2 {
    top: 20%;
    right: 2%;
    width: 9%;
  }
  .RSangria-proleaf .proleafgrp32 {
    width: 23%;
  }
  .RSangria-proleaf .proleafblur3 {
    top: 1%;
    right: 3%;
  }
  .RSangria-proleaf .proleafgrp11 {
    top: 18%;
    right: 30%;
  }
  .RSangria-proleaf .proleafblur5 {
    bottom: -2%;
    left: -6%;
    width: 30%;
  }
  .RSangria-proleaf .proleafgrp41 {
    width: 26%;
  }
  .RSangria-proleaf .proleafgrp12 {
    width: 16%;
  }

  /* .productpagelist .slide {
    margin-top: 0vh;
  } */
  .productteawrapper .RSangria-main-product-heding h1 {
    margin-top: 0vh;
  }
  .productlistofsmallbox .productteawrapper .RSangria-main-product-heding h1 {
    margin-top: 40vh;
  }
  /* .productlistofsmallbox .productpagelist .slide {
    margin-top: 68vh;
  } */
  .ordernowbutton {
    width: 80%;
  }
}
@media screen and (min-width: 900px) and (orientation: portrait) {
  .OurSangria-main-headding-wrapper {
    height: auto;
    margin: 0%;
  }
  .OurSangria-main-headding-wrapper .realSangria-container {
    height: 100%;
    min-height: 400px;
  }
  .hero__title {
    width: 100%;
    min-height: auto;
    top: 5vh;
  }
  .backgroundeimage-figure {
    width: 100%;
    top: 32vh;
    height: 45vh;
  }
  .bottole-figure {
    width: 100%;
    height: 50%;
    background-position: calc(50% - 90px) 100%;
  }
  .bottole-750-figure {
    width: 100%;
    height: 50%;
    bottom: -15px;
    background-position: calc(50% + 15px) 100%;
  }
  .bottole-tin-figure {
    width: 100%;
    height: 27.5%;
    bottom: 8px;
    background-position: calc(50% - 190px) 100%;
  }
  .bottole-box-figure {
    width: 100%;
    height: 35%;
    background-position: calc(50% + 110px) 100%;
  }
  .backgroundnamescroll {
    left: -50%;
  }
  .backgroundnamescroll h2 {
    font-weight: 700 !important;
    font-size: 20vw;
  }
}
