.OurSangria-detailes-headding h4 {
  text-align: center;
  font-size: 40px;
  margin-top: 1.5rem;
  color: #e3b76f;
  font-weight: 400 !important;
}
.hipRecipes-images,
.hipRecipes-videos {
  display: flex;
  flex-wrap: wrap;
}
.hipRecipes-images-inner,
.hipRecipes-videos-inner {
  overflow: hidden;
  padding: 10px 15px;
  width: 33.33%;
}
.hipRecipes-wrapper {
  margin-bottom: 5rem;
}
.hipRecipes-images-inner-image {
  width: 100%;
  background-color: black;
  overflow: hidden;
  height: 500px;
}
.hipRecipes-videos-inner-image {
  width: 100%;
  overflow: hidden;
  height: 300px;
}
.hipRecipes-images-inner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  cursor: pointer;
  opacity: 0.85;
  position: relative;
  transition: 0.5s ease all;
}
.hipRecipes-images-inner img:hover {
  opacity: 1;
  transform: scale(1.1) rotate(1deg);
}
.hipRecipes-images-inner p {
  text-align: center;
  font-weight: 800;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.hipRecipes-videos-inner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  cursor: pointer;
  opacity: 0.85;
  position: relative;
  transition: 0.5s ease all;
}
.hipRecipes-videos-inner img:hover {
  opacity: 1;
  transform: scale(1.1) rotate(1deg);
}
/* .hipRecipes-videos {
  margin-bottom: 5rem;
} */
.hipRecipes-videos-inner p {
  text-align: center;
  font-size: 30px;
  font-weight: 800;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.animated-element-wrapper .hipRecipes-videos-inner-image.left,
.animated-element-wrapper .hipRecipes-images-inner-image.left {
  -webkit-clip-path: inset(0 100% 0 0);
  clip-path: inset(0 100% 0 0);
}
.animated-element-wrapper .hipRecipes-videos-inner-image.right,
.animated-element-wrapper .hipRecipes-images-inner-image.right {
  -webkit-clip-path: inset(0 0 0 100%);
  clip-path: inset(0 0 0 100%);
}
.animated-element-wrapper .hipRecipes-videos-inner-image.dwon,
.animated-element-wrapper .hipRecipes-images-inner-image.dwon {
  -webkit-clip-path: inset(0 0 100% 0);
  clip-path: inset(0 0 100% 0);
}
.animate-image-hipsip-left .hipRecipes-videos-inner-image,
.animate-image-hipsip-left .hipRecipes-images-inner-image {
  -webkit-animation-name: vamtam-grow-left;
  animation-name: vamtam-grow-left;
  will-change: clip-path;
  animation-duration: 1.5s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
}
.animate-image-hipsip-right .hipRecipes-videos-inner-image,
.animate-image-hipsip-right .hipRecipes-images-inner-image {
  -webkit-animation-name: vamtam-grow-right;
  animation-name: vamtam-grow-right;
  will-change: clip-path;
  animation-duration: 1.5s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
}
.animate-image-hipsip-down .hipRecipes-videos-inner-image,
.animate-image-hipsip-dwon .hipRecipes-images-inner-image {
  -webkit-animation-name: vamtam-grow-down;
  animation-name: vamtam-grow-down;
  will-change: clip-path;
  animation-duration: 1.5s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
}
@media only screen and (max-width: 991px) {
  .hipRecipes-images-inner,
  .hipRecipes-videos-inner {
    width: 50%;
  }
}
@media only screen and (max-width: 676px) {
  .hipRecipes-images-inner,
  .hipRecipes-videos-inner {
    width: 100%;
  }
}
