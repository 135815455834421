.herosection-wrapper {
  position: relative;
  overflow: hidden;
}
.realSangria-herosection-wrapper {
  height: 95vh;
  position: relative;
  /* background-color: rgba(161, 40, 58, 0.15); */
}

.realSangria-container {
  height: 100%;
}
.realSangria-background {
  position: relative;
  height: 100%;
  overflow: hidden;
}
.realSangria-background-image {
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  background-image: url("../images/heroImage.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  transition: all 1s ease;
}

.realSangria-background h1 {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  text-align: left;
  font-family: "Cormorant Infant", serif;
  font-size: 70px;
  font-weight: 300 !important;
  line-height: 1.1em;
  letter-spacing: 0;
  text-align: center;
  margin-top: 0.5rem;
  transition: all ease;
  width: 50%;
}
.realSangria-heroOverlay {
  position: absolute;
  top: 0%;
  left: 0%;
  bottom: 0%;
  right: 0%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.4);
}

.realSangria-Keypoint-wrapper {
  background-color: rgba(233, 176, 168, 0.25);
  height: 400px;
  padding-top: 150px;
  position: relative;
}
.ProductPlaveHome-main-wrapper {
}
.realSangria-container {
  width: 100%;
  height: 100%;
}

/* .realSangria-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  opacity: 0.7;
} */
.realSangria-keyPoints {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.realSangria-keyPoints-inner {
  padding: 5px 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33.33%;
}
.realSangria-keyPoints-inner-one h2 {
  font-size: 70px;
  color: #e3b76f;
  font-family: "Libre Baskerville", serif;
  font-weight: 400 !important;
  margin: 0%;
}
.realSangria-keyPoints-inner-two {
  padding-left: 1rem;
}
.realSangria-keyPoints-inner-two h4 {
  margin: 0%;
  text-transform: uppercase;
}
.realSangria-keyPoints-inner-two p {
  font-size: 16px;
  line-height: 1.2;
}
.animated-element {
  opacity: 0;
}
.animated-element.animate {
  animation: 0.751s ease-in 0s 1 normal forwards running fadeIn;
}
@keyframes move_wave {
  0% {
    transform: translateX(0) scaleY(1.2) scaleX(1.2);
  }
  50% {
    transform: translateX(10px) scaleY(1.05) scaleX(1.2);
  }
  100% {
    transform: translateX(0) scaleY(1.2) scaleX(1.2);
  }
}
@keyframes move_wave_reflct {
  0% {
    transform: translateX(0) scaleY(-1) scaleX(-1);
  }
  50% {
    transform: translateX(10px) scaleY(-0.85) scaleX(-1);
  }
  100% {
    transform: translateX(0) scaleY(-1) scaleX(-1);
  }
}
@keyframes move_wave_reflct_1 {
  0% {
    transform: translateX(0) scaleY(1.2) scaleX(1.2);
  }
  50% {
    transform: translateX(10px) scaleY(1.05) scaleX(1.2);
  }
  100% {
    transform: translateX(0) scaleY(1.2) scaleX(1.2);
  }
}

.waveimage-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1;
  overflow: visible;
  opacity: 0;
}

.winevavw1 {
  position: absolute;
  left: 0%;
  bottom: 190px;
  width: 100%;
  overflow: hidden;
  /*   -webkit-transform: scaleX(-1);
  transform: scaleX(-1); */
  animation: move_wave_reflct_1 4s linear infinite;
  mix-blend-mode: multiply;
}
.winevavw2 {
  position: absolute;
  left: 0%;
  bottom: 160px;
  width: 100%;
  animation: move_wave 6s linear infinite;
  mix-blend-mode: multiply;
}
.winevavw3 {
  -webkit-transform: scaleY(-1) scaleX(-1);
  transform: scaleY(-1) scaleX(-1);
  animation: move_wave_reflct 6s linear infinite;
  position: absolute;
  left: 0%;
  bottom: 150px;
  width: 140%;
}
@media only screen and (max-width: 1400px) {
  .winevavw1 {
    bottom: 275px;
  }
  .winevavw2 {
    bottom: 260px;
  }
  .realSangria-Keypoint-wrapper {
    padding-top: 70px;
  }
}

@media only screen and (max-width: 991px) {
  .realSangria-background h1 {
    width: 100%;
    padding: 0 15px;
    font-size: 50px;
  }
  .realSangria-Keypoint-wrapper {
    height: auto;
  }
  .realSangria-keyPoints {
    flex-wrap: wrap;
    padding: 1rem 0 2rem;
  }
  .realSangria-keyPoints-inner {
    width: 50%;
  }
  .winevavw1 {
    bottom: 25%;
  }
  .winevavw2 {
    bottom: 23%;
  }
  .RealSangria-navbar-wrapper {
    position: relative;
  }
  .realSangria-Keypoint-wrapper {
    padding-top: 10%;
  }
  .realSangria-herosection-wrapper {
    height: 65vh;
  }
}
@media only screen and (max-width: 676px) {
  .realSangria-keyPoints {
    display: block;
  }
  .waveimage-container {
    pointer-events: none;
  }
  .realSangria-keyPoints-inner {
    margin: 0 auto;
    width: auto;
  }
  .winevavw1 {
    bottom: unset;
    top: calc(65vh - 80px);
  }
  .winevavw2 {
    bottom: unset;
    top: calc(65vh - 70px);
  }
  .realSangria-keyPoints-inner-one {
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .realSangria-keyPoints-inner-two {
    width: 100%;
    max-width: 200px;
  }
}
@media only screen and (max-width: 500px) {
  .winevavw1 {
    top: calc(65vh - 60px);
  }
  .winevavw2 {
    top: calc(65vh - 50px);
  }
}
@media only screen and (max-width: 360px) {
  .winevavw1 {
    top: calc(65vh - 50px);
  }
  .winevavw2 {
    top: calc(65vh - 45px);
  }
}
